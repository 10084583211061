import * as React from "react";
import { Create, SimpleForm, TextInput, required, email, SelectInput, CheckboxGroupInput, Button, AutocompleteInput, ReferenceInput, useCreate, useUpdate, useNotify, useRedirect, useRecordContext, Toolbar, SaveButton } from 'react-admin';
import { Grid, Alert, AlertTitle, Typography, TextField, Box, Link } from '@mui/material';
import { ListaProvince, verificaCF, apiBaseUrl, dataProvider } from '../generale';
import moment from 'moment';
import axios from 'axios';
// import { useFormContext } from "react-hook-form";
// import { object } from "prop-types";

export const LeadNuovo = () => {

    const [create, { isLoading, error }] = useCreate();
    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();
    const idUtente = localStorage.getItem('auth_uid');
    const [preloadedLeads, setPreloadedLeads] = React.useState(null);
    const [preloadedSelection, setPreloadedSelection] = React.useState(null);
    const [dataToDuplicate, setDataToDuplicate] = React.useState(null);

    const FormPreCaricato = () => {
        // React.useEffect(() => {
        // console.log(dataToDuplicate);
        //     preloadedLeads.map((obj, i) => {
        //         if (obj.id === daDuplicare)
        //             setPreloadedSelection(preloadedLeads[i]);
        //     })
        //     // console.log(preloadedSelection);
        // }, [daDuplicare]);
        // if (daDuplicare === null) { return null; }

        return (
            <Grid container sx={{pb:5}}>
                <Grid item xs={12} xl={8}>
                    <Grid container columnSpacing={2} rowSpacing={0} sx={{
                        '& .MuiTextField-root': { mb: 0, pb:0 },
                        '& .MuiFormHelperText-root:not(.Mui-error)': { height:0 },
                    }}>
                        <Grid item xs={12}>
                            <TextInput
                                source="nominativo"
                                label="Nominativo"
                                type="text"
                                validate={[required()]}
                                defaultValue={dataToDuplicate.nominativo}
                                fullWidth
                            />
                        </Grid>
                        {/* <Grid item xs={12} /> */}
                        <Grid item xs={12} md={6}>
                            <TextInput
                                fullWidth
                                source="indirizzo"
                                label="Indirizzo"
                                type="text"
                                defaultValue={dataToDuplicate.indirizzo}
                                validate={[required()]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={6} sm={3}>
                                    <TextInput
                                        source="n_civico"
                                        label="N. Civico"
                                        type="text"
                                        validate={[required()]}
                                        defaultValue={dataToDuplicate.n_civico}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={3}>
                                    <TextInput source="cap" label="CAP" type="text" validate={[required()]} defaultValue={dataToDuplicate.cap} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SelectInput fullWidth source="provincia" choices={ListaProvince} optionText="name" optionValue="sigla" validate={[required()]} defaultValue={dataToDuplicate.provincia} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="comune" label="Città" type="text" validate={[required()]} defaultValue={dataToDuplicate.comune} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="pod_pdr" label="POD o PDR" type="text" />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="cod_fisc" label="Codice Fiscale" type="text" validate={[verificaCF]} defaultValue={dataToDuplicate.cod_fisc} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="p_iva" label="Partita IVA" type="text" defaultValue={dataToDuplicate.p_iva} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="telefono" label="Telefono" type="text" validate={[required()]} defaultValue={dataToDuplicate.telefono} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="email" label="E-Mail" type="email" validate={[required(), email()]} defaultValue={dataToDuplicate.email} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth source="note" label="Note" type="text" multiline sx={{ height: '100px' }} />
                        </Grid>

                        <Grid item xs={12}>
                            {privacyCheck.map((opt, i) => (
                                <React.Fragment key={i}>
                                    <CheckboxGroupInput fullWidth source={opt.sourceName} choices={[opt]} optionText={<Typography component='p' variant='small' sx={{lineHeight:1.2, fontSize:'small'}}>{opt.testo}{opt.link !== null ? (<Link sx={{ my: 0, ml:1 }} onClick={(e) => { window.open(opt.link, '_blank') }}>{opt.testoLink}</Link>) : null}</Typography>} validate={[required()]} />
                                </React.Fragment>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const OptionCustom = () => {
        const record = useRecordContext();
        return (
            <span>
                <Box>
                <b>{record.nominativo}</b><br />
                <small>{record.indirizzo}, {record.n_civico} - {record.cap} {record.comune} ({record.provincia})</small>
                </Box>
            </span>
        );
    }

    React.useEffect(() => {
        dataProvider.getList(
            "leads_anag_uni", {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'nominativo', order: 'ASC' },
            filter: { id_utente: idUtente }
        }
        ).then(({ data }) => {
            // console.log(data);
            setPreloadedLeads(data);
        });
        document.title = "Inserisci una nuova segnalazione";
    }, []);

    React.useEffect(() => {
        if (preloadedLeads!== null && preloadedLeads.length > 0) {
            preloadedLeads.map((obj, i) => {
                if (obj.id === preloadedSelection)
                    setDataToDuplicate(preloadedLeads[i]);
            })
        }
    }, [preloadedSelection]);

    // React.useEffect(() => {
    //     console.log(preloadedLeads);
    // }, [preloadedLeads]);

    const onSuccess = async ({ id }) => {
        const idLead = id;
        let dataOra = moment().format("YYYY-MM-DD HH:mm:ss");
        await create('leads_movimenti_stati', {
            data: {
                id_lead: idLead,
                id_stato: 1,
                id_operatore: idUtente,
                data_ora: dataOra
            }
        }, {
            onSuccess: async ({ id }) => {
                let id_ms = { id_movimento_stato: id };
                // console.log(idLead, id_ms);
                await update(
                    'leads',
                    {
                        id: idLead,
                        data: id_ms
                    }, {
                    onSuccess: () => {
                        notify("Lead creato", { type: 'success' });
                        var bodyFormData = new FormData();
                        bodyFormData.append('id', idLead);
                        bodyFormData.append('refer', (window.location.hostname === "localhost" ? "-test" : ""));

                        axios.post(apiBaseUrl + '/operation/nuovo-lead.php', bodyFormData)
                        .then(response => {
                            // console.log(response);
                        });
                        redirect("/leads");
                    },
                    onError: (error) => {
                        notify("Si è verificato un errore con l'inserimento. Riprova più tardi. Se il problema persiste contatta la nostra Assistenza Tecnica.", { type: 'warning' });
                        redirect("/leads");
                    }
                }
                )
            }
        });
    }

    const privacyCheck = [
        { id: 1, link: process.env.PUBLIC_URL + 'informativa_privacy.pdf', testoLink: "Leggi l'informativa", sourceName: 'privacy_1', testo: 'Prendo visione e accetto l’informativa' },
        { id: 2, link: null, testoLink: null, sourceName: 'privacy_2', testo: 'Acconsento al trattamento dei miei dati personali per l’invio, da parte di solaraccess.it , di comunicazioni promozionali relative a prodotti e/o servizi di solaraccess.it o di società ad esso collegate, con l’uso del telefono con operatore e/o anche mediante sistemi automatizzati (es. email, sms).' },
        { id: 3, link: process.env.PUBLIC_URL + 'lista_completa_terzi.pdf', testoLink: "Visualizza la lista completa", sourceName: 'privacy_3', testo: 'Acconsento la cessione per scopi di marketing e commerciali, con l’uso del telefono con operatore e/o con sistemi automatizzati (es. email, sms) e/o invio di materiale promozionale a mezzo posta, a terzi soggetti facenti parte delle seguenti categorie economiche o merceologiche: Turistico, Tempo libero, High Tech, Moda, Arredamento, Largo Consumo, Food & Beverage, Finanza, Banche, Assicurazioni, Energia, Ambiente, Comunicazione, Media, Entertainment, Real Estate, Farmaceutico, Automobili, Abbigliamento e tessile, Formazione, Energia, Editoria, ICT, Retail, Sport, Telecomunicazioni, e Servizi in generale.' },
        { id: 4, link: null, testoLink: null, sourceName: 'privacy_4', testo: 'Acconsento al trattamento dei miei dati personali da parte di solaraccess.it o di terze parti nominate responsabili del trattamento per conto di solaraccess.it consistenti nell’elaborazione delle mie scelte di consumo e delle mie abitudini di acquisto, attraverso operazioni di trattamento tese alla profilazione dell’utente realizzate anche attraverso l’incrocio di dati raccolti in relazione a funzionalità diverse, per finalità di marketing, anche diretto, e per consentire l’invio di materiale informativo e/o pubblicitario di specifico interesse, con l’ausilio di strumenti automatizzati e non automatizzati.' }
    ];

    const inputText = choice => `${choice.nominativo}`;
    const matchSuggestion = (filter, choice) => {
        // console.log(choice);
        if (choice.nominativo !== null && choice.nominativo !== '') {
            return (
                choice.nominativo.toLowerCase().includes(filter.toLowerCase())
            );
        }
        return;
    };
    
    if (preloadedLeads === null) { return <p>Loading</p>; }

    return (
        <Create resource="leads" title="Inserisci una nuova segnalazione" mutationOptions={{ onSuccess }} sx={{pb:5}}>
            <SimpleForm defaultValues={{ creazione: moment().format("YYYY-MM-DD HH:mm:ss"), id_utente: idUtente }} toolbar={<Toolbar><SaveButton label="Invia segnalazione" /></Toolbar>}>
                <Grid container>
                    <Grid item xs={12} xl={8}>
                        <Typography variant="h4">Inserisci una nuova segnalazione</Typography>
                        <Alert severity="info" sx={{ my: "20px" }}>
                            <AlertTitle><b>NOTA BENE</b></AlertTitle>
                            <p><b>Ogni contatto inserito corrisponde ad una diversa fornitura.</b><br />
                            Se vuoi inserire più forniture per uno stesso contatto puoi farlo in maniera indipendente l'una dall'altra ma potrai velocizzare le operazioni di inserimento dati duplicando il contatto dopo averlo inserito la prima volta.</p>
                        </Alert>
                    </Grid>
                </Grid>
                {(
                    preloadedLeads.length > 0 && (dataToDuplicate === null || dataToDuplicate === '')
                    ?
                    <Grid container columnSpacing={2} rowSpacing={1} sx={{'& .MuiTextField-root': { mb: 0, pb:0 }}}>
                        <Grid item xs={12} xl={8}>
                            <h5 sx={{padding:0}}>Seleziona un contatto esistente</h5>
                            <AutocompleteInput
                                choices={preloadedLeads}
                                source="leads"
                                // optionText="nominativo"
                                optionValue="id"
                                defaultValue=""
                                label="Duplica i dati di un contatto già inserito"
                                filterToQuery={txt => ({ nominativo_cs: `${txt}` })}
                                // isOptionEqualToValue={(option, value) => (option.id === value.id ? setPreloadedSelection(value) : null)}
                                optionText={<OptionCustom />}
                                inputText={inputText}
                                matchSuggestion={matchSuggestion}
                                fullWidth

                                // value={(preloadedSelection !== null ? preloadedSelection.nominativo : null)}
                                onChange={(e) => { setPreloadedSelection(e) }}

                                // inputValue={(preloadedSelection !== null ? preloadedSelection.nominativo : null)}
                                // onInputChange={(ev, val) => { setPreloadedSelection(val) }}
                            />
                            <h5 sx={{padding:0}}>Oppure inserisci un nuovo contatto</h5>
                        </Grid>
                    </Grid>
                    :
                    null
                )}
                
                {(
                    dataToDuplicate !== null && dataToDuplicate !== ''
                        ?
                        <FormPreCaricato />
                        :
                        // <Grid container columnSpacing={2} rowSpacing={0}>
                        <Grid container>
                            <Grid item xs={12} xl={8}>
                                <Grid container columnSpacing={2} rowSpacing={0} sx={{
                                    '& .MuiTextField-root': { mb: 0, pb:0 },
                                    '& .MuiFormHelperText-root:not(.Mui-error)': { height:0 },
                                }}>
                                    <Grid item xs={12}>
                                        <TextField
                                            source="nominativo"
                                            label="Nominativo"
                                            type="text"
                                            validate={[required()]}
                                            defaultValue=""
                                            fullWidth
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextInput
                                            fullWidth
                                            source="indirizzo"
                                            label="Indirizzo"
                                            type="text"
                                            validate={[required()]}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Grid container columnSpacing={2} rowSpacing={0}>
                                            <Grid item xs={6} sm={3}>
                                                <TextInput
                                                    source="n_civico"
                                                    label="N. Civico"
                                                    type="text"
                                                    validate={[required()]}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <TextInput source="cap" label="CAP" type="text" validate={[required()]} />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <SelectInput fullWidth source="provincia" choices={ListaProvince} optionText="name" optionValue="sigla" validate={[required()]} />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextInput fullWidth source="comune" label="Città" type="text" validate={[required()]} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput fullWidth source="pod_pdr" label="POD o PDR" type="text" />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextInput fullWidth source="cod_fisc" label="Codice Fiscale" type="text" validate={[verificaCF]} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput fullWidth source="p_iva" label="Partita IVA" type="text" />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <TextInput fullWidth source="telefono" label="Telefono" type="text" validate={[required()]} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput fullWidth source="email" label="E-Mail" type="email" validate={[required(), email()]} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextInput fullWidth source="note" label="Note" type="text" multiline sx={{ height: '100px' }} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        {privacyCheck.map((opt, i) => (
                                            <React.Fragment key={i}>
                                                <CheckboxGroupInput fullWidth source={opt.sourceName} choices={[opt]} optionText={<Typography component='p' variant='small' sx={{lineHeight:1.2, fontSize:'small'}}>{opt.testo}{opt.link !== null ? (<Link sx={{ my: 0, ml:1 }} onClick={(e) => { window.open(opt.link, '_blank') }}>{opt.testoLink}</Link>) : null}</Typography>} validate={[required()]} />
                                            </React.Fragment>
                                        ))}
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                        </Grid>
                )}
            </SimpleForm>
        </Create>
    )
};