import { useState } from 'react';
import { useNotify, useRedirect } from 'react-admin';
import { FormControl, Box, Paper, Grid, Select, InputLabel, MenuItem, Container, TextField, Link, Button, Typography } from '@mui/material';
import axios from 'axios';
import { apiBaseUrl, brand } from "./components/generale";
import { ArrowForwardIos } from '@mui/icons-material';
import { LoadingComponent } from "./components/LoadingComponent";

const ResetPassword = ({ pagina, aggiornamento }) => {
    const [email, setEmail] = useState('');
    const [uploading, setUploading] = useState(false);
    const notify = useNotify();
    const redirect = useRedirect();

    const handleRecuperaPassword = e => {
        e.preventDefault();
        setUploading(true);
        var bodyFormData = new FormData();
        bodyFormData.append('email', e.target.username.value);
        bodyFormData.append('refer', (window.location.hostname === "localhost" ? "-test" : ""));
        bodyFormData.append('b', window.btoa(brand.id));

        axios.post(apiBaseUrl + '/operation/reset-password.php', bodyFormData)
            .then(response => {
                // console.log(response);
                if (response.status === 200 && response.data === 'ok') {
                    notify(`E' stata inviata una richiesta di reset password. Riceverai al tuo indirizzo email ${e.target.username.value} un link per procedere con il cambio della password.`, { type: 'success' });
                    if(pagina === 'login') window.location.reload(false);
                    else redirect('/login')
                } else {
                    notify(`La richiesta di reset password ha riscontrato dei problemi. Verifica i dati inseriti o riprova più tardi.`, { type: 'warning' });
                    setUploading(false);
                }
            });
    };

    const handleGoBack = () => {
        aggiornamento(false);
    }

    return (
        <>
            {!uploading ?
                <form onSubmit={handleRecuperaPassword}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                id="username"
                                label="E-Mail"
                                variant="filled"
                                type="email"
                                fullWidth
                                onChange={e => setEmail(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                            <Button type='submit' variant='contained' color='primary' endIcon={<ArrowForwardIos />}>Reset password</Button>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Link
                                underline="hover"
                                onClick={handleGoBack}
                            >
                                <small>Torna {pagina === 'login' ? 'al login' : 'alla registrazione'}</small>
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            :
            <LoadingComponent />
            }
        </>
    );
};

export default ResetPassword;