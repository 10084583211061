import { useState, useEffect, useRef } from 'react';
import { useLogin, useNotify, Notification, useAuthState, useRedirect } from 'react-admin';
import { Box, Paper, Grid, Container, TextField, Link, Button, Typography } from '@mui/material';
import axios from 'axios';
import { apiBaseUrl, mediaBaseUrl, LoginBg, brand } from "./components/generale";
import { LoadingComponent } from "./components/LoadingComponent";
import ResetPassword from "./ResetPassword";
import { Login, ArrowForwardIos } from '@mui/icons-material';

const MyLoginPage = ({ theme }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginOrReset, setLoginOrReset] = useState(true);
    const [formUploading, setFormUploading] = useState(false);
    const login = useLogin();
    const notify = useNotify();
    const { isLoading, authenticated } = useAuthState();
    const redirect = useRedirect();
    // const compResetPwd = useRef();
    // const logoImageUrl = require(process.env.PUBLIC_URL+'images/logo.png');

    const handleLogin = e => {
        e.preventDefault();
        setFormUploading(true);
        login({ username: email, password }).catch(() => {
            notify('Le credenziali inserite non sono corrette. Verifica e riprova.', { type: 'error' })
            setFormUploading(false);
        }
        );
    };

    function handleBackToLogin() {
        setLoginOrReset(true);
    }

    useEffect(() => {
        document.title = "Accedi alla tua Area Segnalatore";
    }, []);

    if (isLoading) return <LoadingComponent />;
    if (authenticated) redirect('/');
    // if (!brands) return null;
    if (!brand) return null;

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', minHeight: "100vh", backgroundImage: `url(${LoginBg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <Paper sx={{ py: 3, mx: 2, backgroundColor: '#ffffff' }} elevation={10}>
                {!formUploading ?
                    <Container maxWidth="sm">
                        <Box
                            component="img"
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: 'auto', maxWidth: "100%", mb: 2 }}
                            src={`${mediaBaseUrl}${brand.url_logo}`}
                        />
                        <Typography sx={{ textAlign: 'center' }} component="h3" variant='p'>{loginOrReset ? "Accedi alla tua Area Segnalatore" : "Reimposta la password del tuo account"}</Typography>

                        {/* Mostra il modulo di Login */}
                        {loginOrReset === true ?
                            <>
                                <form onSubmit={handleLogin}>
                                    <Grid container columnSpacing={2} rowSpacing={1} sx={{'& .MuiTextField-root': { mb: 0, pb:0 }}}>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                id="username"
                                                label="E-Mail"
                                                variant="filled"
                                                type="email"
                                                fullWidth
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                id="password"
                                                label="Password"
                                                variant="filled"
                                                type="password"
                                                fullWidth
                                                onChange={e => setPassword(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
                                            <Button type='submit' variant='contained' color='primary' startIcon={<Login />} label='Accedi'>Accedi</Button>
                                        </Grid>
                                    </Grid>
                                </form>
                                <Grid container spacing={2} sx={{ mt: 3 }}>
                                    <Grid item xs={12} md={12}>
                                        <Link underline="hover" size="small" onClick={() => { setLoginOrReset(!loginOrReset) }}><small>Hai dimenticato la password?</small></Link>
                                        <br />
                                        <Link underline="hover" size="small" onClick={() => { window.location.href = '/#/registrazione' }}><small>Non sei ancora iscritto? Registrati subito</small></Link>
                                    </Grid>
                                </Grid>
                            </>
                            :
                            <ResetPassword pagina='login' aggiornamento={handleBackToLogin} />
                        }

                    </Container>
                    :
                    <Container maxWidth="sm">
                        <Box
                            component="img"
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: 'auto', maxWidth: "100%", mb: 2 }}
                            src={`${mediaBaseUrl}${brand.url_logo}`}
                        />
                        <LoadingComponent />
                    </Container>
                }
            </Paper>
        </Box>
    );
};

export default MyLoginPage;