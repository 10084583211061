import * as React from "react";
import { List, ListBase, Datagrid, TextField, ReferenceField, EditButton, Link, FunctionField, usePermissions, useRecordContext, useListContext, useNotify } from 'react-admin';
import { Typography, Alert, AlertTitle, Divider, LinearProgress, Box, Grid, Card, Button } from '@mui/material';
import moment from 'moment';
import { dataProvider, apiBaseUrl, brand } from '../generale';
import axios from 'axios';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
        </Box>
    );
}

export const LeadDetails = () => {
    const record = useRecordContext();
    return (
        <>
            <div>
                <p><b>Storico degli aggiornamenti di stato</b></p>
                <List resource="leads_movimenti_stati" filter={{ id_lead: record.id }} exporter={false} perPage={100} sort={{ field: 'id', order: 'DESC' }} pagination={false} sx={{ mt: '-60px', mb: '40px' }}>
                    <Datagrid bulkActionButtons={false}>
                        <FunctionField render={record => `${moment(record.data_ora).format("DD/MM/YYYY HH:mm:ss")}`} label="Data/Ora" />
                        <ReferenceField source="id_stato" reference="leads_stati" label="Stato" link={false} sortable={false}>
                            <TextField source="descr" />
                        </ReferenceField>
                        <TextField source="note" />
                    </Datagrid>
                </List>
            </div>
        </>
    );
}

export const TotalePagabili = ({user_id}) => {
    const [totPagabile, setTotPagabile] = React.useState(0);
    const { data, isLoading } = useListContext();
    var numeral = require('numeral');

    React.useEffect(() => {
        if (!isLoading) {
            setTotPagabile(0);
            const result = data.reduce(
                (previousValue, currentValue) => previousValue + parseFloat(currentValue.valore),
                0,
            );
            setTotPagabile(result);
        }
    }, [data]);

    if (isLoading) return null;
    // console.log(data);

    return (
        <>
            {(totPagabile > 0
                ?
                <>
                    <Typography variant="h6">Presto <b><span>{(`${numeral(totPagabile).value()} €`)}</span></b> saranno tuoi.</Typography>
                    <Alert severity="info" sx={{ my: 2, py: 0 }} icon={false}>
                        <p sx={{ mb: 0, pb: 0 }}>E' importante tenere sempre aggiornato il proprio <Link to={`/utenti/${user_id}`}>Profilo personale</Link> affinchè i pagamenti siano erogati correttamente.</p>
                    </Alert>
                </>
                :
                <Typography variant="h6">Non hai ancora accumulato segnalazioni monetizzabili.</Typography>
            )}
        </>
    );
}

export const TotalePagati = () => {
    const [totPagato, setTotPagato] = React.useState(0);
    const { data, isLoading } = useListContext();
    var numeral = require('numeral');

    React.useEffect(() => {
        if (!isLoading) {
            setTotPagato(0);
            const result = data.reduce(
                (previousValue, currentValue) => previousValue + parseFloat(currentValue.valore),
                0,
            );
            setTotPagato(result);
        }
    }, [data]);

    if (isLoading) return null;
    // console.log(data);

    return (
        <>
            {(totPagato > 0
                ?
                <Typography variant="h6">Hai già guadagnato <b><span>{(`${numeral(totPagato).value()} €`)}</span></b> fino ad oggi</Typography>
                :
                <Typography variant="h6">Non abbiamo ancora erogato alcun compenso.</Typography>
            )}
        </>
    );
}

export const Engagement = () => {
    const { permissions } = usePermissions();
    const userID = localStorage.getItem('auth_uid');
    const filtroLeadsInLav = { id_utente: userID, stato: 2 };
    const filtroLeadsPagabili = { id_utente: userID, stato: 4 };
    const filtroLeadsPagati = { id_utente: userID, stato: 5 };
    // const filtroLeadsAltriStati = { id_utente: userID, stato: '1,3' };
    const [dettaglioUtente, setDettaglioUtente] = React.useState('');
    const [conteggiUtente, setConteggiUtente] = React.useState(0);
    const [mostraProgressBar, setMostraProgressBar] = React.useState(0);
    const [mostraAlertUpgrade, setMostraAlertUpgrade] = React.useState(0);
    const [mostraTastoCS, setMostraTastoCS] = React.useState(0);
    var numeral = require('numeral');

    React.useEffect(() => {
        getUserData();
        document.title = "La tua carriera come Segnalatore";
    }, []);

    React.useEffect(() => {
        getUserCount();
        getLeadsData();
    }, [dettaglioUtente]);

    const getLeadsData = async () => {
        await dataProvider.getList(
            "leads", {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'DESC' },
            filter: { id_utente: userID, },
            meta: { join: 'leads_movimenti_stati' }
        }).then(({ data }) => {
            // console.log(data);
        });
    };

    const getUserData = async () => {
        await dataProvider.getOne(
            "utenti_attivi",
            { id: userID }
        ).then(({ data }) => {
            setDettaglioUtente(data);
            // console.log("ID ruolo segnalatore: " + data.id_ruolo);
        });
    };

    const getUserCount = (lead) => {
        // console.log("ID segnalatore: " + lead.id_utente);
        dataProvider.getList(
            "leads_conteggi", {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'DESC' },
            filter: { id_utente: userID }
        },
        ).then(({ data }) => {
            // console.log(data[0].conteggio);
            if (dettaglioUtente.id_ruolo === 1) {
                setConteggiUtente(parseInt(data[0].conteggio * 100 / 10 > 100 ? 100 : data[0].conteggio * 100 / 10));
            // } else if (dettaglioUtente.id_ruolo === 2) {
            //     setConteggiUtente(parseInt(data[0].conteggio * 100 / 11 > 100 ? 100 : data[0].conteggio * 100 / 11));
            }
        });
    };

    React.useEffect(() => {
        if (dettaglioUtente !== '') {
            // console.log(conteggiUtente);
            if (dettaglioUtente.id_ruolo === 1) {
                setMostraProgressBar(1);
            }
            // if (dettaglioUtente.id_ruolo === 1 && conteggiUtente === 100) {
            //     setMostraAlertUpgrade(1);
            // }
            if (dettaglioUtente.id_ruolo === 1 && dettaglioUtente.richiesta_cs === 1) {
                setMostraTastoCS(1);
                setMostraProgressBar(0);
            }
            if (dettaglioUtente.id_ruolo === 1 && dettaglioUtente.richiesta_cs === 2) {
                setMostraProgressBar(0);
                setMostraTastoCS(2);
            }
        }
    }, [dettaglioUtente, conteggiUtente]);

    const TastoCS = ({ stato, uid }) => {
        const notify = useNotify();
        // console.log(stato);

        const richiestaCS = async () => {
            notify(`Stiamo inviando la tua richiesta. Attendi qualche secondo...`)
            const headers = {
                'X-API-Key': localStorage.getItem('auth_api_key')
            }
            let bodyFormData = new FormData();
            bodyFormData.append('uid', uid);
            bodyFormData.append('refer', (window.location.hostname === "localhost" ? "-test" : ""));
            bodyFormData.append('b', window.btoa(brand.id));

            await axios.post(apiBaseUrl + '/operation/richiesta-cs.php', bodyFormData, { headers: headers })
                .then(response => {
                    if (response.data === 'ok') {
                        setDettaglioUtente({ ...dettaglioUtente, richiesta_cs: 2 });
                        notify(`La richiesta è stata inviata con successo. Presto riceverai risposta dal nostro staff competente.`, { type: 'success' });
                    } else {
                        notify("Non è stato possibile procedere con la richiesta. Riprova più tardi. Se il problema persiste contatta la nostra Assistenza Tecnica.", { type: 'warning' });
                    }
                })
                .catch(() => notify("Non è stato possibile procedere con la richiesta. Riprova più tardi. Se il problema persiste contatta la nostra Assistenza Tecnica.", { type: 'warning' }));
        }

        if (stato === 1)
            return (
                <Button variant="contained" color="error" size="medium" label="" onClick={richiestaCS}>Chiedi di diventare<br/>Country Sale Manager</Button>
            )
        if (stato === 2)
            return (
                <Alert severity="info" sx={{ my: "20px" }}>
                    <AlertTitle><b>RICHIESTA IN FASE DI VALUTAZIONE</b></AlertTitle>
                    <p><b>La tua richiesta è in fase di valutazione</b></p>
                    <p>Hai già inviato la richiesta per la candidatura a <b>Country Sales Manager</b>. Presto riceverai risposta dal nostro staff competente.</p>
                </Alert>
            )
    }

    if (dettaglioUtente === '') return <LoadingComponent />;

    return (
        <Box sx={{ my: 2, pb: 2 }} component="div">
            <RuotaDevice />

            <Typography variant="h4" sx={{ my: 2 }}>La tua carriera</Typography>

            <Grid container spacing={2}>

                <Grid item xs={12} md={6}>
                    <Card sx={{ maxWidth: "unset", padding: "10px", mx: 0 }}>
                        <Typography variant="h6" sx={{mb: 2}}>La tua posizione attuale è <b>{(dettaglioUtente !== '' ? dettaglioUtente.ruolo_utente : '')}</b></Typography>

                        {mostraTastoCS > 0 ?
                            <TastoCS stato={mostraTastoCS} uid={userID} />
                            : null}
                        {/* {mostraAlertUpgrade === 1 ?
                            <Alert severity="success" sx={{ my: "20px" }}>
                                <AlertTitle><b>COMPLIMENTI</b></AlertTitle>
                                <p><b>Hai raggiunto l'obbiettivo mensile</b></p>
                                <p>Dal prossimo mese la tua posizione sarà <b>Senior Engagement</b> e questo ti permetterà di guadagnare di più su ciascun contatto utile che inserirai.</p>
                            </Alert>
                            : null} */}
                        {mostraProgressBar === 1 ?
                            <div>
                                <Typography variant="p">Per raggiungere il prossimo livello sei al</Typography>
                                <LinearProgressWithLabel value={conteggiUtente} />
                            </div>
                            : null}

                        <Box sx={{ mt: "30px" }}>
                            <ListBase resource="leads_statovalore" filter={filtroLeadsInLav} sort={{ field: 'id_lead', order: 'DESC' }} perPage={100} exporter={false} hasCreate={false} pagination={false}>
                                <Datagrid bulkActionButtons={false} rowClick="expand" sortable={0} expand={<LeadDetails />}>
                                    <TextField source="nominativo" sortable={false} />
                                    <ReferenceField source="stato" reference="leads_stati" label="Stato" link={false}>
                                        <TextField source="descr" />
                                    </ReferenceField>
                                    <FunctionField label="Importo" render={record => (`${numeral(record.valore).value()} €`)} />
                                </Datagrid>
                            </ListBase>
                        </Box>

                    </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Card sx={{ maxWidth: "unset", padding: "10px", mx: 0 }}>
                        <ListBase resource="leads_statovalore" filter={filtroLeadsPagabili} sort={{ field: 'data_ora', order: 'DESC' }} perPage={100} exporter={false} hasCreate={false} pagination={false} sx={{ mt: "-60px" }}>
                            <TotalePagabili user_id={userID} />
                            <Datagrid bulkActionButtons={false} sortable={0}>
                                <TextField source="nominativo" sortable={false} />
                                <ReferenceField source="stato" reference="leads_stati" label="Stato" link={false} sortable={false}>
                                    <TextField source="descr" sortable={false} />
                                </ReferenceField>
                                <FunctionField label="Importo" render={record => (`${numeral(record.valore).value()} €`)} />
                            </Datagrid>
                        </ListBase>
                    </Card>
                    <Card sx={{ maxWidth: "unset", padding: "10px", mx: 0, mt: 3 }}>
                        <ListBase resource="leads_statovalore" filter={filtroLeadsPagati} sort={{ field: 'data_ora', order: 'DESC' }} perPage={100} exporter={false} hasCreate={false} pagination={false} sx={{ mt: "-60px" }}>
                            <TotalePagati />
                            <Datagrid bulkActionButtons={false} sortable={0}>
                                <TextField source="nominativo" sortable={false} />
                                <ReferenceField source="stato" reference="leads_stati" label="Stato" link={false} sortable={false}>
                                    <TextField source="descr" sortable={false} />
                                </ReferenceField>
                                <FunctionField label="Importo" render={record => (`${numeral(record.valore).value()} €`)} />
                            </Datagrid>
                        </ListBase>
                    </Card>
                </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={0}>
                <Grid item xs={12} sx={{ mx: 0 }}>
                    <Typography variant="h6">Per vedere tutte le segnalazioni e la relativa situazione fai clic sul tasto</Typography>
                    <Link to="/leads">
                        <Button variant="contained" color="primary" size="medium" label="">Mostra segnalazioni</Button>
                    </Link>
                </Grid>
            </Grid>

        </Box>
    )
};