import * as React from "react";
import { List, Datagrid, TextField, Link, EditButton, useListContext, useRecordContext } from 'react-admin';
import { Grid, Box, Typography } from '@mui/material';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';

export const FaqDetails = () => {
    const record = useRecordContext();
    return (
        <div>
            <p>{record.risposta}</p>
        </div>
    );
}

export const FaqListAdmin = () => {
    React.useEffect(() => {
        document.title = "F.A.Q. - Domande frequenti";
    }, []);

    return (
        <List sort={{ field: 'id', order: 'ASC' }} perPage={50} exporter={false} pagination={false}>
            <Datagrid bulkActionButtons={false} rowClick="expand" sortable={false} expand={<FaqDetails />}>
                <TextField source="domanda" sortable={false} />
                <EditButton label="Modifica"></EditButton>
            </Datagrid>
        </List>
    )
};

export const FaqListUser = () => {
    const ListaFaq = () => {
        const { data, isLoading } = useListContext();
        if (isLoading) return <LoadingComponent />;

        return (
            <Grid container spacing={2} sx={{
                    my: "10px",
                    p: 2,
                    mt: (data.length > 0 ? '-50px' : '')
                }}
            >
                {data.map((faq, i) => (
                    <Box mb={5} width="100%" key={i}>
                        <Typography component="p" variant="h5" mb={1}>{faq.domanda}</Typography>
                        <Typography component="p">{faq.risposta}</Typography>
                    </Box>
                ))}
            </Grid>

        );
    }

    React.useEffect(() => {
        document.title = "F.A.Q. - Domande frequenti";
    }, []);

    return (
        <List sort={{ field: 'id', order: 'ASC' }} perPage={50} exporter={false} pagination={false} component="div" empty={<EmptyPage testo='faq' />}
        // sx={{ mt: "-50px" }} 
        >
            <ListaFaq />
        </List>
    )
};