import * as React from "react";
import { Edit, SelectInput, AutocompleteInput, TextInput, BooleanInput, required, email, SaveButton, useRecordContext, TabbedForm, FormTab, Toolbar, Button, useUpdate, useEditContext, useNotify, useGetList } from 'react-admin';
import { ListaProvince, ListaRuoliUtenti, ReteDiretta, verificaCF, verificaIban } from '../generale';
import { Grid, Divider, Alert, AlertTitle } from '@mui/material';

const TitoloSezione = () => {
    const record = useRecordContext();
    return <span>Modifica Utente: <b>{record ? `"${record.nominativo} (ID: ${record.id})"` : ''}</b></span>;
};

export const UtenteModifica = () => {
    const [update] = useUpdate();
    const notify = useNotify();

    const TabCandidatura = () => {
        const record = useRecordContext();

        const approvaCS = async () => {
            update(
                'utenti',
                { id: record.id, data: { richiesta_cs: 3, id_ruolo: 3 } }, {
                onSuccess: () => {
                    notify(`La richiesta è stata approvata. L'utente è ora Country Sales Manager.`, { type: 'success' });
                },
                onError: () => {
                    notify(`La richiesta non è stata inviata con successo. Riprova più tardi.`, { type: 'error' });
                }
            })
        }

        const rifiutaCS = async () => {
            update(
                'utenti',
                { id: record.id, data: { richiesta_cs: -1 } }, {
                onSuccess: () => {
                    notify(`La richiesta è stata rifiutata. L'utente resterà Senior Engagement e non potrà più fare richieste.`, { type: 'success' });
                },
                onError: () => {
                    notify(`La richiesta non è stata inviata con successo. Riprova più tardi.`, { type: 'error' });
                }
            })
        }

        if (!record) return null;
        // console.log(record.richiesta_cs);
        if (record.richiesta_cs === 2)
            return (
                <Alert severity="warning" sx={{ my: "20px" }}>
                    <AlertTitle><b>COUNTRY SALES MANAGER</b></AlertTitle>
                    <p><b>L'utente ha richiesto di diventare Country Sales Manager</b></p>
                    <Button variant="contained" color="success" size="medium" label="Approva" onClick={approvaCS} />
                    <Button variant="contained" color="error" size="medium" label="Rifiuta" onClick={rifiutaCS} sx={{ ml: 2 }} />
                </Alert>
            )
        if (record.richiesta_cs === 3)
            return (
                <Alert severity="success" sx={{ my: "20px" }} variant="outlined">
                    <AlertTitle><b>COUNTRY SALES MANAGER</b></AlertTitle>
                    <p><b>L'utente è stato approvato come Country Sales Manager</b></p>
                </Alert>
            )
        if (record.richiesta_cs === -1)
            return (
                <Alert severity="error" sx={{ my: "20px" }} variant="outlined">
                    <AlertTitle><b>COUNTRY SALES MANAGER</b></AlertTitle>
                    <p><b>L'utente NON è stato approvato come Country Sales Manager</b></p>
                </Alert>
            )
    }

    React.useEffect(() => {
        document.title = "Modifica Utente";
    }, []);

    return (
        <Edit resource="utenti" title={<TitoloSezione />} sx={{ pb: 8 }}>
            <TabbedForm toolbar={<Toolbar><SaveButton /></Toolbar>}>
                <FormTab label="Accesso">
                    <Grid container columnSpacing={2} rowSpacing={0}>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextInput source="email" label="E-Mail" type="email" disabled={true} validate={[required(), email()]} fullWidth />
                            {/* <FunctionField render={record => (
                                (record.ultimo_login !== null && record.ultimo_login !== '' ? `Ultimo login: ${moment(record.ultimo_login).format("DD/MM/YYYY HH:mm:ss")}` : `Ultimo login: N.D.`)
                            )} label="" /> */}
                            {/* <ReteDiretta /> */}
                            <ListaRuoliUtenti richiesto={true} />
                            <TabCandidatura />
                            <BooleanInput source="stato" fullWidth />
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab label="Anagrafica">
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="nominativo" label="Nome e Cognome" type="text" validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="cod_fisc" label="Codice Fiscale" type="text" validate={[required(), verificaCF]} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="telefono" label="Telefono" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="rag_soc" label="Ragione Sociale" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="p_iva" label="Partita IVA" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="sdi" label="Codice SDI" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={6} lg={3}>
                            <TextInput source="comune" label="Città" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextInput source="indirizzo" label="Indirizzo" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={12} lg={5}>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextInput fullWidth source="n_civico" label="N. Civico" type="text" />
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextInput fullWidth source="cap" label="CAP" type="text" />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <SelectInput fullWidth source="provincia" choices={ListaProvince} optionText="name" optionValue="sigla" />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="iban" label="IBAN" type="text" fullWidth validate={[verificaIban]} />
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
};