import { fetchUtils, useGetList, SelectInput, AutocompleteInput, required, regex, useStore } from 'react-admin';
import treeqlProvider from 'ra-data-treeql';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import it, { ra } from 'ra-language-italian';
import loginBgUrl from '../includes/img/login.jpg';
import axios from 'axios';

export const LoginBg = loginBgUrl;
export const idUtentiReteDiretta = [14];

// Rimpiazzare l'oggetto seguente con il download dal Core Genesis (blocco commentato di seguito)
export const brands = [
    {
        id: "4",
        nome: "Catania Energia",
        alias: "cte",
        url_logo: "images/brands/logo-cte.jpg",
        ragione_sociale: "Civita NovaEnergia S.r.l.",
        sede_legale: "Via Silvio Pellico, 8 - 62012 Civitanova Marche (MC)",
        p_iva: "02022680439",
        dominio: "catania-energia.it",
        email_privacy: "privacy@civitanovaenergia.it",
        email_dpo: "dpo@civitanovaenergia.it",
        stato: "1",
        id_mandante: "2"
    },
    {
        id: "1",
        nome: "Civita NovaEnergia",
        alias: "cvte",
        url_logo: "images/brands/logo-cvte.jpg",
        ragione_sociale: "Civita NovaEnergia S.r.l.",
        sede_legale: "Via Silvio Pellico, 8 - 62012 Civitanova Marche (MC)",
        p_iva: "02022680439",
        dominio: "civitanovaenergia.it",
        email_privacy: "privacy@civitanovaenergia.it",
        email_dpo: "dpo@civitanovaenergia.it",
        stato: "1",
        id_mandante: "2"
    },
    {
        id: "3",
        nome: "Ragusana Energia",
        alias: "rge",
        url_logo: "images/brands/logo-rge.jpg",
        ragione_sociale: "Ragusana Energia S.r.l.",
        sede_legale: "Via Eugenio Criscione Lupis, 2 - 97100 Ragusa (RG)",
        p_iva: "01744510882",
        dominio: "ragusanaenergia.it",
        email_privacy: "privacy@ragusanaenergia.it",
        email_dpo: "dpo@ragusanaenergia.it",
        stato: "1",
        id_mandante: "2"
    },
    {
        id: "5",
        nome: "Siracusana Energia",
        alias: "sre",
        url_logo: "images/brands/logo-sre.jpg",
        ragione_sociale: "Civita NovaEnergia S.r.l.",
        sede_legale: "Via Silvio Pellico, 8 - 62012 Civitanova Marche (MC)",
        p_iva: "02022680439",
        dominio: "siracusanaenergia.it",
        email_privacy: "privacy@civitanovaenergia.it",
        email_dpo: "dpo@civitanovaenergia.it",
        stato: "1",
        id_mandante: "2"
    }
];

// export const brands = axios.post(
//     `https://${(window.location.hostname === "localhost" ? "test." : "")}core.genesis7.it/api/functions.php`, { 
//         "call_type":"lista-brands","data": {"type": "full_details"}
//     }, { headers: {
//         'mode': 'no-cors',
//         'credentials': 'same-origin',
//         'Content-Type': 'application/json;charset=UTF-8',
//         'Origin': 'https://test.core.genesis7.it/',
//         'Access-Control-Allow-Origin': '*'
//     }}
// );

export const brand = brands.find(b => {
    if (window.location.hostname === "localhost")
        return ('areasegnalatori.civitanovaenergia.it').includes(b.dominio)
    else
        return (window.location.hostname).includes(b.dominio)
});

const fetchJson = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    // add your own headers here
    // options.headers.set('X-Custom-Header', 'foobar');
    // options.headers.set('Access-Control-Expose-Headers', 'Content-Range');
    // options.headers.set('Access-Control-Expose-Headers', 'X-Total-Count');
    options.headers.set('Content-Type', 'text/plain');
    // options.headers.set('X-API-Key', '83ba807e-d8af-44d2-869c-415a716d5d6a');
    options.headers.set('X-API-Key', localStorage.getItem('auth_api_key'));
    return fetchUtils.fetchJson(url, options);
}
export const apiBaseUrl = 'https://areasegnalatori.'+brand.dominio+'/api';
export const mediaBaseUrl = 'https://media.genesis7.it/';
export const apiFullUrl = apiBaseUrl + '/api' + (window.location.hostname === "localhost" ? "-test" : "") + '.php';
export const hCaptcha_sitekey = (window.location.hostname === "localhost" ? "10000000-ffff-ffff-ffff-000000000001" : "e223fa49-3dba-4a15-bdb8-26567fa1a8c5");
export const apiUsersFilesUrl = mediaBaseUrl + 'areasegnalatori/upusr/';
export const verificaCFpattern = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
export const verificaPWDpattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const verificaIbanPattern = /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/;
export const verificaEmailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const verificaCF = regex(verificaCFpattern, "Inserire un codice fiscale valido");
export const verificaPWD = regex(verificaPWDpattern, 'La password deve contenere almeno 8 caratteri, di cui almeno una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&)');
export const verificaIban = regex(verificaIbanPattern, 'L\'Iban non deve contenere spazi vuoti e le lettere vanno riportate in maiuscolo.');
export const dataProvider = treeqlProvider(apiFullUrl, fetchJson);

export const overrideIT = {
    ...it,
    ra: {
        ...it.ra,
        page: {
            ...it.ra.page,
            dashboard: 'Dashboard',
        }
    }
};

export const i18nProvider = polyglotI18nProvider(() => overrideIT, 'it', { allowMissing: true });

export const ListaProvince = [{
    id: 1,
    name: 'Agrigento',
    sigla: 'AG'
},
{
    id: 2,
    name: 'Alessandria',
    sigla: 'AL'
},
{
    id: 3,
    name: 'Ancona',
    sigla: 'AN'
},
{
    id: 4,
    name: 'Aosta',
    sigla: 'AO'
},
{
    id: 5,
    name: 'Arezzo',
    sigla: 'AR'
},
{
    id: 6,
    name: 'Ascoli Piceno',
    sigla: 'AP'
},
{
    id: 7,
    name: 'Asti',
    sigla: 'AT'
},
{
    id: 8,
    name: 'Avellino',
    sigla: 'AV'
},
{
    id: 9,
    name: 'Bari',
    sigla: 'BA'
},
{
    id: 10,
    name: 'Barletta - Andria - Trani',
    sigla: 'BT'
},
{
    id: 11,
    name: 'Belluno',
    sigla: 'BL'
},
{
    id: 12,
    name: 'Benevento',
    sigla: 'BN'
},
{
    id: 13,
    name: 'Bergamo',
    sigla: 'BG'
},
{
    id: 14,
    name: 'Biella',
    sigla: 'BI'
},
{
    id: 15,
    name: 'Bologna',
    sigla: 'BO'
},
{
    id: 16,
    name: 'Bolzano',
    sigla: 'BZ'
},
{
    id: 17,
    name: 'Brescia',
    sigla: 'BS'
},
{
    id: 18,
    name: 'Brindisi',
    sigla: 'BR'
},
{
    id: 19,
    name: 'Cagliari',
    sigla: 'CA'
},
{
    id: 20,
    name: 'Caltanissetta',
    sigla: 'CL'
},
{
    id: 21,
    name: 'Campobasso',
    sigla: 'CB'
},
{
    id: 22,
    name: 'Carbonia - Iglesias',
    sigla: 'CI'
},
{
    id: 23,
    name: 'Caserta',
    sigla: 'CE'
},
{
    id: 24,
    name: 'Catania',
    sigla: 'CT'
},
{
    id: 25,
    name: 'Catanzaro',
    sigla: 'CZ'
},
{
    id: 26,
    name: 'Chieti',
    sigla: 'CH'
},
{
    id: 27,
    name: 'Como',
    sigla: 'CO'
},
{
    id: 28,
    name: 'Cosenza',
    sigla: 'CS'
},
{
    id: 29,
    name: 'Cremona',
    sigla: 'CR'
},
{
    id: 30,
    name: 'Crotone',
    sigla: 'KR'
},
{
    id: 31,
    name: 'Cuneo',
    sigla: 'CN'
},
{
    id: 32,
    name: 'Enna',
    sigla: 'EN'
},
{
    id: 33,
    name: 'Fermo',
    sigla: 'FM'
},
{
    id: 34,
    name: 'Ferrara',
    sigla: 'FE'
},
{
    id: 35,
    name: 'Firenze',
    sigla: 'FI'
},
{
    id: 36,
    name: 'Foggia',
    sigla: 'FG'
},
{
    id: 37,
    name: 'Forlì- Cesena',
    sigla: 'FC'
},
{
    id: 38,
    name: 'Frosinone',
    sigla: 'FR'
},
{
    id: 39,
    name: 'Genova',
    sigla: 'GE'
},
{
    id: 40,
    name: 'Gorizia',
    sigla: 'GO'
},
{
    id: 41,
    name: 'Grosseto',
    sigla: 'GR'
},
{
    id: 42,
    name: 'Imperia',
    sigla: 'IM'
},
{
    id: 43,
    name: 'Isernia',
    sigla: 'IS'
},
{
    id: 44,
    name: 'La Spezia',
    sigla: 'SP'
},
{
    id: 45,
    name: 'L’aquila',
    sigla: 'AQ'
},
{
    id: 46,
    name: 'Latina',
    sigla: 'LT'
},
{
    id: 47,
    name: 'Lecce',
    sigla: 'LE'
},
{
    id: 48,
    name: 'Lecco',
    sigla: 'LC'
},
{
    id: 49,
    name: 'Livorno',
    sigla: 'LI'
},
{
    id: 50,
    name: 'Lodi',
    sigla: 'LO'
},
{
    id: 51,
    name: 'Lucca',
    sigla: 'LU'
},
{
    id: 52,
    name: 'Macerata',
    sigla: 'MC'
},
{
    id: 53,
    name: 'Mantova',
    sigla: 'MN'
},
{
    id: 54,
    name: 'Massa - Carrara',
    sigla: 'MS'
},
{
    id: 55,
    name: 'Matera',
    sigla: 'MT'
},
{
    id: 56,
    name: 'Medio Campidano',
    sigla: 'VS'
},
{
    id: 57,
    name: 'Messina',
    sigla: 'ME'
},
{
    id: 58,
    name: 'Milano',
    sigla: 'MI'
},
{
    id: 59,
    name: 'Modena',
    sigla: 'MO'
},
{
    id: 60,
    name: 'Monza E Brianza',
    sigla: 'MB'
},
{
    id: 61,
    name: 'Napoli',
    sigla: 'NA'
},
{
    id: 62,
    name: 'Novara',
    sigla: 'NO'
},
{
    id: 63,
    name: 'Nuoro',
    sigla: 'NU'
},
{
    id: 64,
    name: 'Ogliastra',
    sigla: 'OG'
},
{
    id: 65,
    name: 'Olbia - Tempio',
    sigla: 'OT'
},
{
    id: 66,
    name: 'Oristano',
    sigla: 'OR'
},
{
    id: 67,
    name: 'Padova',
    sigla: 'PD'
},
{
    id: 68,
    name: 'Palermo',
    sigla: 'PA'
},
{
    id: 69,
    name: 'Parma',
    sigla: 'PR'
},
{
    id: 70,
    name: 'Pavia',
    sigla: 'PV'
},
{
    id: 71,
    name: 'Perugia',
    sigla: 'PG'
},
{
    id: 72,
    name: 'Pesaro E Urbino',
    sigla: 'PU'
},
{
    id: 73,
    name: 'Pescara',
    sigla: 'PE'
},
{
    id: 74,
    name: 'Piacenza',
    sigla: 'PC'
},
{
    id: 75,
    name: 'Pisa',
    sigla: 'PI'
},
{
    id: 76,
    name: 'Pistoia',
    sigla: 'PT'
},
{
    id: 77,
    name: 'Pordenone',
    sigla: 'PN'
},
{
    id: 78,
    name: 'Potenza',
    sigla: 'PZ'
},
{
    id: 79,
    name: 'Prato',
    sigla: 'PO'
},
{
    id: 80,
    name: 'Ragusa',
    sigla: 'RG'
},
{
    id: 81,
    name: 'Ravenna',
    sigla: 'RA'
},
{
    id: 82,
    name: 'Reggio Calabria',
    sigla: 'RC'
},
{
    id: 83,
    name: 'Reggio Emilia',
    sigla: 'RE'
},
{
    id: 84,
    name: 'Rieti',
    sigla: 'RI'
},
{
    id: 85,
    name: 'Rimini',
    sigla: 'RN'
},
{
    id: 86,
    name: 'Roma',
    sigla: 'RM'
},
{
    id: 87,
    name: 'Rovigo',
    sigla: 'RO'
},
{
    id: 88,
    name: 'Salerno',
    sigla: 'SA'
},
{
    id: 89,
    name: 'Sassari',
    sigla: 'SS'
},
{
    id: 90,
    name: 'Savona',
    sigla: 'SV'
},
{
    id: 91,
    name: 'Siena',
    sigla: 'SI'
},
{
    id: 92,
    name: 'Siracusa',
    sigla: 'SR'
},
{
    id: 93,
    name: 'Sondrio',
    sigla: 'SO'
},
{
    id: 94,
    name: 'Taranto',
    sigla: 'TA'
},
{
    id: 95,
    name: 'Teramo',
    sigla: 'TE'
},
{
    id: 96,
    name: 'Terni',
    sigla: 'TR'
},
{
    id: 97,
    name: 'Torino',
    sigla: 'TO'
},
{
    id: 98,
    name: 'Trapani',
    sigla: 'TP'
},
{
    id: 99,
    name: 'Trento',
    sigla: 'TN'
},
{
    id: 100,
    name: 'Treviso',
    sigla: 'TV'
},
{
    id: 101,
    name: 'Trieste',
    sigla: 'TS'
},
{
    id: 102,
    name: 'Udine',
    sigla: 'UD'
},
{
    id: 103,
    name: 'Varese',
    sigla: 'VA'
},
{
    id: 104,
    name: 'Venezia',
    sigla: 'VE'
},
{
    id: 105,
    name: 'Verbano - Cusio - Ossola',
    sigla: 'VB'
},
{
    id: 106,
    name: 'Vercelli',
    sigla: 'VC'
},
{
    id: 107,
    name: 'Verona',
    sigla: 'VR'
},
{
    id: 108,
    name: 'Vibo Valentia',
    sigla: 'VV'
},
{
    id: 109,
    name: 'Vicenza',
    sigla: 'VI'
},
{
    id: 110,
    name: 'Viterbo',
    sigla: 'VT'
}
];

export const ListaRuoliUtenti = ({ richiesto }) => {
    const { data, isLoading, error } = useGetList(
        "utenti_ruoli",
        {
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'id', order: 'ASC' }
        },
    );
    if (isLoading) { return <p>Loading</p>; }
    if (error) { return <p>ERROR</p>; }
    if (richiesto === true) {
        return <SelectInput source="id_ruolo" label="Ruolo utente" choices={data} optionText="descr" optionValue="id" validate={[required()]} />;
    } else {
        return <SelectInput source="id_ruolo" label="Ruolo utente" choices={data} optionText="descr" optionValue="id" />;
    }
}

export const ReteDiretta = ({ richiesto }) => {
    const { data, isLoading, error } = useGetList(
        "utenti",
        {
            pagination: { page: 1, perPage: 9999 },
            sort: { field: 'nominativo', order: 'ASC' }
        },
    );
    if (isLoading) { return <p>Loading</p>; }
    if (error) { return <p>ERROR</p>; }
    const optionRenderer = data => `${data.nominativo} (ID: ${data.id})`;
    if (richiesto === true) {
        return <AutocompleteInput source="inserito_da" label="Appartiene alla rete diretta di" choices={data} optionText={optionRenderer} optionValue="id" parse={v => (v !== '' ? v : null)} validate={[required()]} />;
    } else {
        return <AutocompleteInput source="inserito_da" label="Appartiene alla rete diretta di" choices={data} optionText={optionRenderer} optionValue="id" parse={v => (v !== '' ? v : null)} />;
    }
}