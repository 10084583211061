import React from 'react';
import { Edit, SelectInput, TextInput, required, useNotify, SimpleForm, Toolbar, SaveButton, FileInput, FileField, ListBase, Datagrid, FunctionField, Button, useUpdate, useRedirect, useEditContext, useGetList, useRefresh } from 'react-admin';
import { useParams } from "react-router-dom";
import { apiUsersFilesUrl, apiFullUrl, verificaCF, verificaIban, ListaProvince, brand } from '../generale';
import { Grid, Card, CardContent, Box, Typography, Alert, AlertTitle } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import axios from 'axios';
import { LoadingComponent } from '../LoadingComponent';

const UploadedFiles = ({ nFiles }) => {
    const { record, isLoadingRecordData } = useEditContext();
    const { data: docsCaricati, isLoading, error } = useGetList(
        "uploadfile/" + record.id,
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
        },
    );

    // React.useEffect(() => {
    // }, [nFiles])

    if (isLoading || isLoadingRecordData) { return <LoadingComponent />; }
    if (error) { return <p>ERROR</p>; }

    if (docsCaricati.length > 0)
        return (
            <Alert
                severity='info'
                sx={{ my: "20px" }}
            >
                <AlertTitle><b>I tuoi documenti</b></AlertTitle>
                <Typography variant="p" sx={{ my: 2 }}>Ad oggi ci hai fornito i seguenti documenti. Nel caso non fossero aggiornati o risultino incompleti puoi sempre aggiungerne di nuovi.</Typography>
                <ul style={{paddingLeft: 20, columnCount: 2}}>
                    {docsCaricati.map((record, i) =>
                        <li key={record.id}><a href={apiUsersFilesUrl + record.nome} target="_blank">Allegato {i + 1}</a></li>
                    )}
                </ul>
            </Alert>
        )
    else if (docsCaricati.length === 0 && nFiles === 0)
        return (
            <Alert
                severity='warning'
                sx={{ my: "20px" }}
            >
                <AlertTitle><b>NOTA BENE</b></AlertTitle>
                <p><b>Non hai ancora inserito alcun documento personale</b></p>
                <p>Per procedere con il pagamento di quanto riuscirai ad accumulare abbiamo bisogno dei tuoi documenti; fino a quel momento non saremo in grado di erogare alcun compenso.</p>
            </Alert>
        )
}

const ProfiloUtente = ({ staticContext, ...props }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const { id: userID } = useParams();
    const [arrayFile, setArrayFile] = React.useState([]);
    const [nFileDaCaricare, setNFileDaCaricare] = React.useState(0);

    React.useEffect(() => {
        document.title = "Il tuo profilo personale";
    }, []);

    React.useEffect(() => {
        setNFileDaCaricare(arrayFile.length);
    }, [arrayFile]);

    function handleUpload(data) {
        // console.log(arrayFile);
        notify('Caricamento in corso', { type: 'info' });

        axios.post(apiFullUrl + '/uploadfile/' + userID, arrayFile, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-API-Key': localStorage.getItem('auth_api_key')
            }
        })
            .then(response => {
                // console.log(response);
                setArrayFile([]);
                notify('Modifica salvata', { type: 'success' });
                refresh();
            });
    }

    return (
        <Edit
            resource='utenti'
            title={"Il tuo profilo personale"}
            mutationMode="pessimistic"
            mutationOptions={{
                onSuccess: (data) => {
                    // console.log(data);
                    handleUpload(data);
                }
            }}
        >
            <SimpleForm
                toolbar={<Toolbar {...props} ><SaveButton /></Toolbar>}
            >
                <Box sx={{ my: 0, pb: 2, mb:5, pt: 0 }}>
                    <Typography variant="h4" sx={{ my: 2 }}>Il tuo profilo</Typography>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={7} lg={8} xl={9}>
                            <Grid container columnSpacing={2} rowSpacing={0} sx={{
                                '& .MuiTextField-root': { mb: 0, pb:0 },
                                '& .MuiFormHelperText-root:not(.Mui-error)': { height:0 },
                            }}>
                                <Grid item xs={12} md={4} xl={4}>
                                    <TextInput source="nominativo" label="Nome e Cognome" type="text" validate={[required()]} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} md={4} xl={4}>
                                    <TextInput source="cod_fisc" label="Codice Fiscale" type="text" validate={[required(), verificaCF]} fullWidth disabled />
                                </Grid>
                                <Grid item xs={12} md={4} xl={4}>
                                    <TextInput source="telefono" label="Telefono" type="text" validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={12} />
                                <Grid item xs={12} md={4} xl={4}>
                                    <TextInput source="rag_soc" label="Ragione Sociale" type="text" fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4} xl={4}>
                                    <TextInput source="p_iva" label="Partita IVA" type="text" fullWidth />
                                </Grid>
                                <Grid item xs={12} md={4} xl={4}>
                                    <TextInput source="sdi" label="Codice SDI" type="text" fullWidth />
                                </Grid>
                                <Grid item xs={12} />
                                <Grid item xs={12} md={6} lg={3}>
                                    <TextInput source="comune" label="Città" type="text" validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <TextInput source="indirizzo" label="Indirizzo" type="text" validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={12} md={12} lg={5}>
                                    <Grid container columnSpacing={2}>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput fullWidth source="n_civico" label="N. Civico" type="text" validate={[required()]} />
                                        </Grid>
                                        <Grid item xs={6} sm={3} md={3}>
                                            <TextInput fullWidth source="cap" label="CAP" type="text" validate={[required()]} />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <SelectInput fullWidth source="provincia" choices={ListaProvince} optionText="name" optionValue="sigla" validate={[required()]} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} xl={4}>
                                    <TextInput source="iban" label="IBAN" type="text" fullWidth validate={[required(), verificaIban]} />
                                </Grid>
                                <Grid item xs={12} />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={5} lg={4} xl={3} sx={{mt:1}}>
                            <Grid container columnSpacing={2} rowSpacing={0} sx={{
                                '& .MuiTextField-root': { mb: 0, pb:0 },
                                '& .MuiFormHelperText-root:not(.Mui-error)': { height:0 },
                            }}>
                                <Grid item xs={12}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <AlertTitle><b>Carica i tuoi documenti</b></AlertTitle>
                                            <FileInput
                                                source="allegati"
                                                accept="image/*,application/pdf"
                                                placeholder={
                                                    <>
                                                    <CloudUpload />
                                                    {(nFileDaCaricare > 0 ?
                                                    <div>Aggiungi altri file o procedi al caricamento di {nFileDaCaricare} file premi il tasto "Salva"</div>
                                                    :
                                                    <div>Carica i tuoi documenti nei formati jpg/png/pdf (max 4 Mb cad.).</div>
                                                    )}
                                                    </>
                                                }
                                                maxSize={4000000}
                                                multiple
                                                onChange={(e) => setArrayFile(e)}
                                            >
                                                <FileField source="src" title="title" />
                                            </FileInput>
                                            <UploadedFiles nFiles={nFileDaCaricare} />
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </SimpleForm>
        </Edit>
    );
};

export default ProfiloUtente;