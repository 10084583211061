import { useState, useEffect, useRef } from 'react';
import { Form, useNotify, CheckboxGroupInput, required, useAuthState, useRedirect, useUpdate, useLogin } from 'react-admin';
import { FormControl, Box, Paper, Grid, Select, InputLabel, MenuItem, Container, TextField, Link, Button, Typography, Alert } from '@mui/material';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { apiBaseUrl, mediaBaseUrl, verificaCFpattern, verificaPWDpattern, verificaEmailPattern, ListaProvince, LoginBg, brand, apiFullUrl, hCaptcha_sitekey } from "./components/generale";
import { ArrowForwardIos, HowToReg } from '@mui/icons-material';
import ResetPassword from "./ResetPassword";
import { LoadingComponent } from "./components/LoadingComponent";
import HCaptcha from '@hcaptcha/react-hcaptcha';

const RegisterPage = ({ theme }) => {
    const [statoForm, setStatoForm] = useState(false);
    const [erroriForm, setErroriForm] = useState([]);
    const [captchaToken, setCaptchaToken] = useState(false);
    const [nominativo, setNominativo] = useState('');
    const [codFisc, setCodFisc] = useState('');
    const [telefono, setTelefono] = useState('');
    const [citta, setCitta] = useState('');
    const [provincia, setProvincia] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [registerOrReset, setRegisterOrReset] = useState(true);
    const [formUploading, setFormUploading] = useState(false);
    const [emailEsistente, setEmailEsistente] = useState(false);
    const notify = useNotify();
    const { isLoading, authenticated } = useAuthState();
    const redirect = useRedirect();
    const login = useLogin();
    const [update, { isLoading: isLoadingUpdate, error: errorUpdate }] = useUpdate();
    // const logoImageUrl = require(process.env.PUBLIC_URL+'images/logo.png');

    useEffect(() => {
        document.title = "Registrati come Segnalatore per " + brand.nome;
    }, []);

    useEffect(() => {
        // console.log(erroriForm)
        if (
            captchaToken === false
            || nominativo === ''
            || codFisc === ''
            || telefono === ''
            || citta === ''
            || provincia === ''
            || email === ''
            || password === ''
        ) {
            setStatoForm(false);
        } else {
            setStatoForm(true);
        }
    }, [
        captchaToken,
        nominativo,
        codFisc,
        telefono,
        citta,
        provincia,
        email,
        password
    ]);

    const handleRegistrazione = e => {
        // console.log(nominativo);
        // console.log(codFisc);
        // console.log(telefono);
        // console.log(citta);
        // console.log(provincia);
        // console.log(email);
        // console.log(password);

        if (!captchaToken) return false;
        setFormUploading(true);
        setEmailEsistente(false);
        var idUtente;
        var bodyFormData = new URLSearchParams();
        bodyFormData.append('username', email);
        bodyFormData.append('password', password);

        axios.post(apiFullUrl + '/register', bodyFormData)
            .then(async response => {
                // console.log(response);
                idUtente = parseInt(response.data.id);
                if (idUtente > 0) {
                    bodyFormData.append('nominativo', nominativo);
                    bodyFormData.append('cod_fisc', codFisc);
                    bodyFormData.append('telefono', telefono);
                    bodyFormData.append('comune', citta);
                    bodyFormData.append('provincia', provincia);
                    bodyFormData.append('creazione', e.creazione);
                    bodyFormData.append('temp', password);
                    bodyFormData.delete('username');
                    bodyFormData.delete('password');
                    // console.log(apiFullUrl + '/utenti/' + idUtente);
                    await axios.put(apiFullUrl + '/records/utenti/' + idUtente, bodyFormData, { headers: { 'X-API-Key': response.data.api_key } })
                        .then(async response2 => {
                            // console.log(response2);
                            bodyFormData.forEach((v, k) => {
                                bodyFormData.delete(k);
                            })
                            // Richiamare script per l'invio della password temporanea e la sua eliminazione dalla tabella (campo "temp")
                            bodyFormData.append('b', window.btoa(brand.id));
                            bodyFormData.append('uid', idUtente);
                            bodyFormData.append('refer', (window.location.hostname === "localhost" ? "-test" : ""));
                            axios.post(apiBaseUrl + '/operation/nuovo-utente.php', bodyFormData)
                                .then(response => {
                                    if (response.data === 'ok') {
                                        notify('Registrazione completata. Benvenuto/a nella tua area segnalatore', { type: 'success' });
                                        login({ username: email, password })
                                            .catch(() =>
                                                notify('errore login')
                                            )
                                    } else {
                                        notify('L\'utente è stato creato correttamente ma ci sono stati problemi con la password.', { type: 'warning' });
                                    }
                                })
                        })
                }

            })
            .catch(function (error) {
                // notify(`${error.response.data.code} ${error.response.data.message}`);
                if (error.response.data.code === 1020) {
                    notify('Sembra che questo indirizzo email sia già stato utilizzato. Verifica i dati nella tua casella di posta elettronica.', { type: 'info' });
                    bodyFormData.delete('password');
                    bodyFormData.append('email', email);
                    bodyFormData.append('b', window.btoa(brand.id));
                    bodyFormData.append('refer', (window.location.hostname === "localhost" ? "-test" : ""));
                    axios.post(apiBaseUrl + '/operation/utente-esistente.php', bodyFormData);
                    // setFormUploading(false);
                    setEmailEsistente(true);
                }
            });
    };

    const handleChangeProvincia = (e) => {
        setProvincia(e.target.value);
    }

    function handleBackToLogin() {
        setRegisterOrReset(true);
    }

    // const handleRecuperaPassword = e => {
    //     e.preventDefault();
    //     setFormUploading(true);
    //     var bodyFormData = new FormData();
    //     bodyFormData.append('email', e.target.username.value);
    //     bodyFormData.append('refer', (window.location.hostname === "localhost" ? "-test" : ""));
    //     bodyFormData.append('b', window.btoa(brand.id));

    //     axios.post(apiBaseUrl + '/operation/reset-password.php', bodyFormData)
    //         .then(response => {
    //             // console.log(response);
    //             if (response.status === 200 && response.data === 'ok') {
    //                 notify(`E' stata inviata una richiesta di reset password. Riceverai al tuo indirizzo email ${e.target.username.value} un link per procedere con il cambio della password.`, { type: 'success' });
    //                 redirect('/login');
    //             } else {
    //                 notify(`La richiesta di reset password ha riscontrato dei problemi. Verifica i dati inseriti o riprova più tardi.`, { type: 'warning' });
    //                 setFormUploading(false);
    //             }
    //         });
    // };

    if (isLoading) return <LoadingComponent />;
    if (authenticated) redirect('/');

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', minHeight: "100vh", backgroundImage: `url(${LoginBg})`, backgroundSize: 'cover', backgroundPosition: 'center', py: 5 }}>
            <Paper sx={{ py: 3, my: 3, mx: 2, backgroundColor: '#ffffff' }} elevation={10}>
                {!formUploading ?
                <Container maxWidth="sm">
                    <Box
                        component="img"
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: 'auto', maxWidth: "100%", mb: 2 }}
                        src={`${mediaBaseUrl}${brand.url_logo}`}
                    />

                    <Typography sx={{ textAlign: 'center' }} component="h3" variant='p'>{registerOrReset === true ? `Registrati come Segnalatore per ${brand.nome}` : "Reimposta la password del tuo account"}</Typography>

                    {/* Mostra il modulo di Registrazione */}
                    {registerOrReset === true ?
                        <>
                            {/* <SimpleForm onSubmit={handleRegistrazione} sx={{padding:0}}> */}
                            <Form
                                onSubmit={handleRegistrazione}
                                sx={{ padding: 0 }}
                                defaultValues={{
                                    creazione: moment().format("YYYY-MM-DD HH:mm:ss"),
                                    api_key: uuidv4()
                                }}
                            >
                                <Grid container columnSpacing={2} rowSpacing={0}
                                    // sx={{'& .MuiTextField-root': { mb: 0, pb:0 }}}
                                >
                                    <Grid item xs={12}>
                                        <TextField
                                            name="nominativo"
                                            label="Nome e Cognome"
                                            type="text"
                                            fullWidth
                                            required
                                            onChange={e => setNominativo(e.target.value)}
                                            error={false}
                                            // sx={{mb:0, pb:0}}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={6}>
                                        <TextField
                                            name="cod_fisc"
                                            label="Codice Fiscale"
                                            type="text"
                                            fullWidth
                                            required
                                            error={(erroriForm.includes('cod_fisc'))}
                                            helperText={(erroriForm.includes('cod_fisc') ? "Verifica la correttezza del dato inserito (scrivere tutto maiuscolo)" : null)}
                                            onBlur={e => {
                                                setErroriForm([]);
                                                const cf = (e.target.value).toUpperCase();
                                                if (verificaCFpattern.test(cf)) {
                                                    setCodFisc(cf);
                                                    setErroriForm((current) =>
                                                        current.filter((val) => val !== e.target.name)
                                                    );
                                                } else {
                                                    setCodFisc('');
                                                    setErroriForm(current => [...current, e.target.name]);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={6}>
                                        <TextField
                                            name="telefono"
                                            label="Telefono"
                                            type="text"
                                            fullWidth
                                            required
                                            onChange={e => setTelefono(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={6}>
                                        <TextField
                                            name="comune"
                                            label="Città"
                                            type="text"
                                            fullWidth
                                            required
                                            onChange={e => setCitta(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={6}>
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="lbl_provincia" required>Provincia</InputLabel>
                                            <Select
                                                fullWidth
                                                required
                                                labelId="lbl_provincia"
                                                label="Provincia"
                                                placeholder="Provincia"
                                                value={provincia}
                                                onChange={handleChangeProvincia}
                                                variant="filled"
                                            >
                                                {ListaProvince.map((record, i) =>
                                                    <MenuItem key={i} value={record.sigla}>{record.name}</MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            id="username"
                                            label="E-Mail"
                                            variant="filled"
                                            type="email"
                                            name="email"
                                            fullWidth
                                            required
                                            // onChange={e => setEmail(e.target.value)}
                                            helperText={(erroriForm.includes('email') ? "L'indirizzo email inserito non è formalmente corretto. Verifica e correggi gli errori per favore" : null)}
                                            error={(erroriForm.includes('email'))}
                                            onBlur={e => {
                                                if (verificaEmailPattern.test(e.target.value)) {
                                                    setErroriForm((current) =>
                                                        current.filter((val) => val !== e.target.name)
                                                    );
                                                    setEmail(e.target.value);
                                                } else {
                                                    setEmail('');
                                                    setErroriForm(current => [...current, e.target.name]);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            id="password"
                                            name="password"
                                            label="Password"
                                            variant="filled"
                                            type="password"
                                            helperText={(erroriForm.includes('password') ? "La password deve contenere almeno 8 caratteri, di cui almeno una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&)" : "Inserisci una password con almeno una maiuscola, una minuscola, un numero e un carattere speciale (@$!%*?&)")}
                                            error={(erroriForm.includes('password'))}
                                            fullWidth
                                            required
                                            onBlur={e => {
                                                if (verificaPWDpattern.test(e.target.value)) {
                                                    setErroriForm((current) =>
                                                        current.filter((val) => val !== e.target.name)
                                                    );
                                                    setPassword(e.target.value);
                                                } else {
                                                    setPassword('');
                                                    setErroriForm(current => [...current, e.target.name]);
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <CheckboxGroupInput sx={{mb:0, pb:0}} source="privacy_1" label="Accettazione Privacy" validate={required([])}
                                            choices={[{ id: '1', name: 'Confermo di aver preso visione dell’informativa sulla tutela dei dati personali' }]}
                                            optionText={<Typography component='p' variant='small' sx={{lineHeight:1.2}}>Confermo di aver preso visione dell’informativa sulla tutela dei dati personali <Link href='/informativa_privacy.pdf' target='_blank'>Leggi l'informativa completa</Link></Typography>}
                                        />
                                        {/* <small><Link href='/informativa_privacy.pdf' target='_blank' variant='small'>Leggi l'informativa completa</Link></small> */}
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <HCaptcha
                                            sitekey={hCaptcha_sitekey}
                                            onVerify={(token, ekey) => setCaptchaToken(token)}
                                            onError={() => setCaptchaToken(false)}
                                            onExpire={() => setCaptchaToken(false)}
                                            onChalExpired={() => setCaptchaToken(false)}
                                        />
                                        <Button
                                            type='submit'
                                            variant='contained'
                                            color='primary'
                                            startIcon={<HowToReg />}
                                            label='Registrati'
                                            disabled={statoForm === false ? true : false}
                                        >Registrati</Button>
                                    </Grid>
                                </Grid>
                            </Form>
                            <Grid container spacing={2} sx={{ mt: 3 }}>
                                <Grid item xs={12} md={12}>
                                    <Link underline="hover" size="small" onClick={() => { setRegisterOrReset(!registerOrReset) }}><small>Hai dimenticato la password?</small></Link>
                                    <br />
                                    <Link underline="hover" size="small" onClick={() => { window.location.href = '/#/login' }}><small>Sei già iscritto? Accedi ora</small></Link>
                                </Grid>
                            </Grid>
                        </>
                    :
                        <ResetPassword pagina='registrazione' aggiornamento={handleBackToLogin} />
                    }
                </Container>
                :
                <Container maxWidth="sm">
                    <Box
                        component="img"
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: 'auto', maxWidth: "100%", mb: 2 }}
                        src={`${mediaBaseUrl}${brand.url_logo}`}
                    />
                    {!emailEsistente ?
                    <LoadingComponent />
                    :
                    <Alert severity="info">
                        <Typography variant='p' component='p'>Sembra che questo indirizzo email sia già stato utilizzato. Verifica i dati nella tua casella di posta elettronica.</Typography>
                    </Alert>
                    }
                </Container>
                }
            </Paper>
        </Box>

    );
};

export default RegisterPage;