import * as React from "react";
import { List, ListBase, Datagrid, TextField, Button, ReferenceField, FunctionField, usePermissions, useRecordContext, useGetList } from 'react-admin';
import { useMediaQuery, Modal, Box, Link } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { EmptyPage } from '../EmptyPage';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { ButtonPagato } from './BottonePagamento';
import { apiUsersFilesUrl } from '../generale';
import FsLightbox from "fslightbox-react";

var numeral = require('numeral');

const UploadedFiles = ({ id_utente, setShow, show }) => {
    let sources = [];
    // const [fsSource, setFsSource] = React.useState(null);
    // const [fsShow, setFsShow] = React.useState(false);
    // const [fsType, setFsType] = React.useState('image');
    const { data: docsCaricati, isLoading, error } = useGetList(
        "uploadfile/" + id_utente,
        {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'id', order: 'ASC' },
        },
    );

    // React.useEffect(() => {
    //     console.log('effect', fsSource, fsType)
    //     if (fsSource) {
    //         setFsShow(true)
    //         setShow(false)
    //     } else {
    //         setFsShow(false)
    //         setShow(true)
    //     }
    // }, [fsSource])

    if (isLoading) { return <LoadingComponent />; }
    if (error) { return <p>ERROR</p>; }

    return (
        <>

            <ClickAwayListener onClickAway={() => {
                if (show) return null
                setShow(false);
            }}>
                <Modal
                    open={show}
                    onClose={() => setShow(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        {(docsCaricati.length <= 0 ?
                        <p><b>Questo segnalatore non ha ancora caricato documenti</b></p>
                        :
                        <ul style={{ paddingLeft: 20, columnCount: 2 }}>
                            {docsCaricati.map((record, i) =>
                                <li key={record.id}>
                                    <a href={apiUsersFilesUrl + record.nome} target="_blank">Allegato {i + 1}</a>
                                    {/* <Link underline="hover" size="small" onClick={() => {
                                        console.log(apiUsersFilesUrl + record.nome);

                                        if ((record.nome).includes('.pdf')) {
                                            console.log('si')
                                            setFsType(null)
                                            setFsSource(<iframe src={apiUsersFilesUrl + record.nome} width="1000px" height="500px"></iframe>)
                                        } else {
                                            console.log('no')
                                            setFsType('image')
                                            setFsSource(apiUsersFilesUrl + record.nome)

                                        }
                                    }}>
                                        <small>Allegato {i + 1}</small>
                                    </Link> */}
                                </li>
                            )}
                        </ul>
                        )}
                    </Box>
                </Modal>
            </ClickAwayListener>

            {/* <FsLightbox
                toggler={fsShow}
                onClose={() => {
                    console.log('onClose', fsSource);
                    // setFsShow(false)
                    // setShow(true)
                    setFsSource(null)
                }}
                sources={[fsSource]}
                onOpen={() => {
                    console.log('onOpen', fsSource);
                }}
                type={fsType}
                style={{ backgroundColor: 'red' }}
            /> */}

        </>
    )
}

export const LeadDetails = () => {
    const record = useRecordContext();
    const [apriModalAllegati, setApriModalAllegati] = React.useState(false);

    if (!record) return <LoadingComponent />;
    // console.log(record);
    return (
        <>
            <p><b>Dettagli del segnalatore</b></p>
            <ListBase disableSyncWithLocation resource="utenti" filter={{ id: record.id_utente }} exporter={false} perPage={100} sort={{ field: 'id', order: 'DESC' }} pagination={false} sx={{ mt: '-60px', mb: '40px' }}>
                <Datagrid bulkActionButtons={false} sx={{ backgroundColor: '#f1f1f1' }}>
                    <FunctionField sortable={false} render={record => (record.rag_soc !== '' && record.rag_soc !== null ? record.rag_soc : record.nominativo)} label="Nominativo" />
                    <FunctionField sortable={false} render={record => (`${record.indirizzo}, ${record.n_civico} - ${record.cap} ${record.comune} (${record.provincia})`)} label="Indirizzo" />
                    <TextField source="cod_fisc" label="Cod. Fisc." sortable={false} />
                    {(record.p_iva !== '' && record.p_iva !== null
                        ? <TextField source="p_iva" label="P.IVA" sortable={false} />
                        : null)}
                    {(record.sdi !== '' && record.sdi !== null
                        ? <TextField source="sdi" label="SDI" sortable={false} />
                        : null)}
                    <TextField source="telefono" sortable={false} />
                    <TextField source="email" sortable={false} />
                    <Button label="Vedi allegati" variant="text" onClick={() => { setApriModalAllegati(true); /*console.log(record.id_utente);*/ }}></Button>
                </Datagrid>
            </ListBase>

            <p><b>Leads inclusi nel pagamento</b></p>
            <ListBase disableSyncWithLocation resource="leads_pagabili_dettaglio" filter={{ id_utente: record.id_utente }} exporter={false} perPage={100} sort={{ field: 'id', order: 'DESC' }} pagination={false} sx={{ mt: '-60px', mb: '40px' }}>
                <Datagrid bulkActionButtons={false} sx={{ backgroundColor: '#f1f1f1' }}>
                    <TextField source="id" />
                    <ReferenceField source="id" reference="leads" label="Nominativo" link={false} sortable={false}>
                        <TextField source="nominativo" />
                    </ReferenceField>
                    <FunctionField sortable={false} render={record => (`${numeral(parseFloat(record.valore)).value()} €`)} label="Valore" />
                </Datagrid>
            </ListBase>
            <UploadedFiles id_utente={record.id_utente} setShow={setApriModalAllegati} show={apriModalAllegati} />
        </>
    );
}

export const ListaLeadsPagabili = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    const [nominativoSegnalatore, setNominativoSegnalatore] = React.useState(null);
    const record = useRecordContext();

    React.useEffect(() => {
        document.title = "Lista dei Leads pagabili";
    }, []);


    const CustomTextField = () => {
        const record = useRecordContext();
        if (record)
            setNominativoSegnalatore(record.nominativo);
        return record.nominativo
    }

    return (
        <>
            <RuotaDevice />
            <List sort={{ field: 'id', order: 'DESC' }} perPage={50} empty={<EmptyPage testo='leads pagabili' />} exporter={false}>
                <Datagrid bulkActionButtons={false} expand={<LeadDetails />}>
                    <TextField source="id" label="ID utente" />
                    {/* <ReferenceField source="id_utente" reference="utenti" label="Segnalatore" link={false}>
                        <FunctionField render={record => setNominativoSegnalatore(record.nominativo)} />
                    </ReferenceField> */}
                    <ReferenceField source="id_utente" reference="utenti" label="Nominativo segnalatore" link={false}>
                        <TextField source="nominativo" />
                    </ReferenceField>
                    <ReferenceField source="id_utente" reference="utenti" label="Iban" link={false}>
                        <TextField source="iban" />
                    </ReferenceField>
                    <FunctionField sortable={false} render={record => (`${numeral(parseFloat(record.totale)).value()} €`)} label="Totale" />
                    {permissions === '5' ?
                        // <FunctionField sortable={false} render={record => (<ButtonPagato id_utente={record.id_utente} nominativo={false} />)} />
                        <ReferenceField source="id_utente" reference="utenti" label="Segnalatore" link={false}>
                            <FunctionField sortable={false} render={record => (<ButtonPagato utente={record} />)} />
                        </ReferenceField>
                        : null}
                </Datagrid>
            </List>
        </>
    )
};