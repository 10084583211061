import * as React from "react";
import { useUpdate, useRedirect, useNotify, Create, TabbedForm, FormTab, TextInput, BooleanInput, required, email, PasswordInput, SelectInput, useRecordContext } from 'react-admin';
import { Grid } from '@mui/material';
import { apiBaseUrl, ListaProvince, ListaRuoliUtenti, ReteDiretta, verificaCF, verificaIban } from '../generale';
import moment from 'moment';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const UtenteNuovo = () => {

    const [update, { isLoading, error }] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();

    React.useEffect(() => {
        document.title = "Inserisci un nuovo utente";
    }, []);

    // if (isLoading) return '<p>Caricamento</p>';

    const onSuccess = ({ id }) => {
        var bodyFormData = new FormData();
        bodyFormData.append('uid', id);
        bodyFormData.append('action', 'generate');

        axios.post(apiBaseUrl + '/operation/pwd-hasher.php', bodyFormData)
            .then(response => {
                // console.log(response);
                update(
                    'utenti',
                    { id, data: { hashed_pwd: response.data.hashed_pwd, temp: response.data.pwd } }, {
                    // { id, data: { hashed_pwd: 'aaaa', temp: 'bbbb' } }, {
                    onSuccess: () => {
                        // Richiamare script per l'invio della password temporanea e la sua eliminazione dalla tabella (campo "temp")
                        bodyFormData.delete('action');
                        bodyFormData.append('refer', (window.location.hostname === "localhost" ? "-test" : ""));
                        axios.post(apiBaseUrl + '/operation/nuovo-utente.php', bodyFormData)
                            .then(response => {
                                if (response.data === 'ok') {
                                    notify('Utente creato correttamente', { type: 'success' });
                                    redirect(`/utenti`);
                                } else {
                                    notify('L\'utente è stato creato correttamente ma ci sono stati problemi con la password.', { type: 'warning' });
                                }
                            })
                    }
                }
                )
            });
    };

    return (
        <Create resource="utenti" title="Nuovo Utente" mutationOptions={{ onSuccess }} sx={{ pb: 8 }}>
            <TabbedForm defaultValues={{ creazione: moment().format("YYYY-MM-DD HH:mm:ss"), api_key: uuidv4() }}>
                <FormTab label="Accesso">
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextInput fullWidth source="email" label="E-Mail" type="email" validate={[required(), email()]} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <ListaRuoliUtenti richiesto={true} />
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={6} lg={4}>
                            {/* <ReteDiretta /> */}
                            <BooleanInput source="stato" />
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab label="Anagrafica">
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="nominativo" label="Nome e Cognome" type="text" validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="cod_fisc" label="Codice Fiscale" type="text" validate={[required(), verificaCF]} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="telefono" label="Telefono" type="text" validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="rag_soc" label="Ragione Sociale" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="p_iva" label="Partita IVA" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="sdi" label="Codice SDI" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={6} lg={3}>
                            <TextInput source="comune" label="Città" type="text" validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextInput source="indirizzo" label="Indirizzo" type="text" validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={12} lg={5}>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextInput fullWidth source="n_civico" label="N. Civico" type="text" validate={[required()]} />
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextInput fullWidth source="cap" label="CAP" type="text" validate={[required()]} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <SelectInput fullWidth source="provincia" choices={ListaProvince} optionText="name" optionValue="sigla" validate={[required()]} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="iban" label="IBAN" type="text" fullWidth validate={[required(), verificaIban]} />
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Create>
    )
};