import * as React from 'react';
import { CircularProgress, Box, Typography, Card, CardContent, useMediaQuery } from '@mui/material';

export const LoadingComponent = () => (
    <Card sx={{backgroundColor: 'rgba(0,0,0,0.15)'}} elevation={5}>
        <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '100%', width: '100%' }}>
                <CircularProgress /><br />
                <Typography variant="h6">Caricamento in corso</Typography>
            </Box>
        </CardContent>
    </Card>
)